import type { EditCompany } from '../../types/server/generics/company'
import CompanyService, { useListCompanies, useReadCompany } from '../../services/company-service'
import Dropdown, { DropdownProps } from '../../components/Dropdown'
import style from '../../assets/style.json'
import Employees from './Employees'
import Swal from 'sweetalert2'
import { LanguageContext } from '../../locales/LocaleContext'
import { CirclePlusIcon } from '../../assets/CirclePlusIcon'
import { useNavigate, useParams } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { TrashIcon } from '../../assets/TrashIcon'
import { useTranslation } from 'react-i18next'
import {
	Container,
	Text,
	Box,
	Button,
	Input,
	VStack,
	SimpleGrid,
	Select,
	Table,
	Tr,
	Td,
	Th,
	Checkbox,
	SlideFade,
	useMediaQuery,
	Switch,
	Skeleton,
	SkeletonCircle,
} from '@chakra-ui/react'

export const Th1 = (props: any) => (
	<Text
		as='th'
		textTransform='uppercase'
		fontSize='xs'
		color='gray.500'
		fontWeight='medium'
		px={4}
		{...props}
	/>
)

export const Td1 = (props: any) => (
	<Box
		as='td'
		color='gray.900'
		p={4}
		borderBottom='1px solid'
		borderBottomColor='gray.100'
		{...props}
	/>
)

export const Tr1 = (props: any) => (
	<Box
		as='tr'
		backgroundColor='gray.50'
		borderTopLeftRadius={8}
		borderTopRightRadius={8}
		borderBottom='1px solid'
		borderBottomColor='gray.200'
		height='40px'
		{...props}
	/>
)

export const Table1 = (props: any) => {
	return (
		<Box
			as='table'
			textAlign='left'
			backgroundColor='white'
			ml={0}
			mr={0}
			borderRadius={8}
			boxShadow='0px 4px 10px rgba(0, 0, 0, 0.05)'
			{...props}
		/>
	)
}

const SkeletonRow = (width: any) => (
	<Box as='tr'>
		<Td>
			<Skeleton
				height='10px'
				w={width}
				my={4}
			/>
		</Td>
		<Td>
			<Skeleton
				height='10px'
				w={width}
				my={4}
			/>
		</Td>
		<Td>
			<Skeleton
				height='10px'
				w={width}
				my={4}
			/>
		</Td>
		<Td>
			<Skeleton
				height='10px'
				w={width}
				my={4}
			/>
		</Td>
		<Td>
			<SkeletonCircle
				size='6'
				width={'40px'}
				borderRadius={'10px'}
			/>
		</Td>
	</Box>
)

const ModifyCompany = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { id } = useParams()
	// const [loading, setLoading] = useState(false);
	const [submitting, setSubmitting] = useState(false)
	const [isPc] = useMediaQuery('(min-width: 1300px)')
	const [isMobile] = useMediaQuery('(max-width: 676px)')

	const { locale } = useContext(LanguageContext)

	const { data: companies, isLoading, mutate: mutateCompanies } = useListCompanies()

	const { data, mutate } = useReadCompany(id)

	// Make editiable state
	const [company, setCompany] = useState<EditCompany | null>(
		data
			? {
					...data,
					employees: (data?.employees ?? []).filter((e) => e.name && e.phone),
					language: data?.language || 'en',
			  }
			: null
	)

	console.log({ company })

	useEffect(() => {
		console.log('Setting company', data)
		if (id && data) {
			setCompany({
				...data,
				employees: (data?.employees ?? []).filter((e) => e.name && e.phone),
				language: data?.language || 'en',
			})
		} else {
			setCompany({
				employees: [],
				exposureCategories: [],
				field: '',
				isActive: true,
				language: 'en',
				name: '',
				onboardingIncludeRoleIds: [],
				roles: [],
				onboardingQuestionnaireId: '',
				website: '',
				tasksSms: false,
				tasksSmsTime: '',
				tasksSmsLastSent: '',
			})
		}
	}, [id, data])

	console.log({ company, companies })

	const submit = async () => {
		if (!company) return

		if (!company.name?.trim()) {
			Swal.fire(t('modifyCompany.invalidName'), t('modifyCompany.enterCompanyName'), 'warning')
			return
		}
		if (company.exposureCategories?.some((categorey: any) => !categorey.name?.trim())) {
			Swal.fire(t('modifyCompany.invalidName'), t('modifyCompany.enterRiskNames'), 'warning')
			return
		}

		if (
			company.exposureCategories?.some(
				(categorey) =>
					company.exposureCategories &&
					company.exposureCategories.filter((c) => categorey.name?.trim() === c.name?.trim()).length > 1
			)
		) {
			Swal.fire(t('modifyCompany.invalidName'), t('modifyCompany.uniqueRiskNames'), 'warning')
			return
		}

		if (company.roles?.some((role) => !role.name?.trim())) {
			Swal.fire(t('modifyCompany.invalidName'), t('modifyCompany.enterRoleNames'), 'warning')
			return
		}

		if (
			company.roles?.some(
				(role) =>
					company.roles && company.roles.filter((r) => role?.name?.trim() === r?.name?.trim()).length > 1
			)
		) {
			return Swal.fire(t('modifyCompany.invalidName'), t('modifyCompany.uniqueRoleNames'), 'warning')
		}

		if (company.employees.some((employee) => !employee.name?.trim())) {
			return Swal.fire(t('modifyCompany.invalidEmployee'), t('modifyCompany.invalidEmployeeName'), 'warning')
		}

		if (company.employees.some((employee) => !employee.phone?.trim())) {
			return Swal.fire(t('modifyCompany.invalidPhoneNumber'), t('modifyCompany.enterPhoneNumbers'), 'warning')
		}

		if (!company.language) {
			company.language = 'en'
		}

		setSubmitting(true)

		try {
			await CompanyService.modify(company)
			await mutate()
			await mutateCompanies()
			navigate('/admin/companies')
		} catch (e) {}

		setSubmitting(false)
	}

	return (
		<Container
			position='absolute'
			top={'90px'}
			maxW='full'
			minH='100vh'
			py={20}
			centerContent
		>
			<SlideFade in>
				<Text
					color={'white'}
					dir='rtl'
					marginBottom={'77px'}
					fontWeight={500}
					fontSize={32}
				>
					{id ? t('modifyCompany.editCompany') : t('modifyCompany.addCompany')}
				</Text>
			</SlideFade>

			{isLoading && id !== undefined ? (
				<>
					<Box
						padding={'22px'}
						width={'100%'}
						maxW={style['pc-view']}
						rounded='10px'
						bg={'#fff'}
						boxShadow='0px 0px 25px rgba(62, 62, 62, 0.08);'
						display={'flex'}
						flexDirection='column'
						gap={'20px'}
					>
						<Box
							bg='#F5F8FA'
							p={'21px'}
							rounded='10px'
						>
							<Text
								fontSize={'22px'}
								fontWeight='600'
								mb={'22px'}
							>
								{t('modifyCompany.companyDetails')}
							</Text>
							<VStack
								w='100%'
								spacing={2}
							>
								<SimpleGrid
									w='100%'
									columns={{ base: 1, md: 2 }}
									spacingX={4}
									spacingY={2}
								>
									<Box>
										<Text
											marginBottom={'5px'}
											fontSize={'14px'}
											color='#aaa'
											fontWeight='14px'
										>
											{t('modifyCompany.companyName')}
										</Text>
										<Skeleton height={'54px'} />
									</Box>
									<Box>
										<Text
											marginBottom={'5px'}
											fontSize={'14px'}
											color='#aaa'
											fontWeight='14px'
										>
											{t('modifyCompany.field')}
										</Text>
										<Skeleton height={'54px'} />
									</Box>
									<Box>
										<Text
											marginBottom={'5px'}
											fontSize={'14px'}
											color='#aaa'
											fontWeight='14px'
										>
											{t('modifyCompany.siteLink')}
										</Text>
										<Skeleton height={'54px'} />
									</Box>
									<Box>
										<Text
											marginBottom={'5px'}
											fontSize={'14px'}
											color='#aaa'
											fontWeight='14px'
										>
											{t('modifyCompany.active')}
										</Text>
										<SkeletonCircle
											size='6'
											width={'40px'}
											borderRadius={'10px'}
										/>
									</Box>
								</SimpleGrid>
							</VStack>
						</Box>

						<Box
							bg='#F5F8FA'
							p={'21px'}
							rounded='10px'
						>
							<Text
								fontSize={'22px'}
								fontWeight='600'
								mb={'22px'}
							>
								{t('modifyCompany.risks')}
							</Text>
							<SimpleGrid
								columns={isPc ? 3 : 1}
								spacing={2}
							>
								<Box pos='relative'>
									<Text
										marginBottom={'5px'}
										fontSize={'14px'}
										color='#aaa'
										fontWeight='14px'
									>
										{t('modifyCompany.riskName')}
									</Text>
									<Skeleton height={'54px'} />
								</Box>
								<Box pos='relative'>
									<Text
										marginBottom={'5px'}
										fontSize={'14px'}
										color='#aaa'
										fontWeight='14px'
									>
										{t('modifyCompany.riskName')}
									</Text>
									<Skeleton height={'54px'} />
								</Box>
								<Box pos='relative'>
									<Text
										marginBottom={'5px'}
										fontSize={'14px'}
										color='#aaa'
										fontWeight='14px'
									>
										{t('modifyCompany.riskName')}
									</Text>
									<Skeleton height={'54px'} />
								</Box>
								{isPc && (
									<Box pos='relative'>
										<Text
											marginBottom={'5px'}
											fontSize={'14px'}
											color='#aaa'
											fontWeight='14px'
										>
											{t('modifyCompany.riskName')}
										</Text>
										<Skeleton height={'54px'} />
									</Box>
								)}
								<Box
									border='1.2521px dashed #3FB2D2'
									rounded='10px'
									justifyContent='center'
									alignItems={'center'}
									display={'flex'}
									gap={'16px'}
									marginTop='20px'
									padding={'10px'}
									color={'#3FB2D2'}
									cursor='pointer'
								>
									<Skeleton
										height='40px'
										width='200px'
										borderRadius='2xl'
									/>
								</Box>
							</SimpleGrid>
						</Box>

						<Box
							bg='#F5F8FA'
							p={'21px'}
							rounded='10px'
						>
							<Text
								fontSize={'22px'}
								fontWeight='600'
								mb={'22px'}
							>
								{t('modifyCompany.roles')}
							</Text>
							<SimpleGrid
								w='100%'
								columns={isPc ? 3 : 1}
								spacing={2}
							>
								<Skeleton
									h='134px'
									rounded='10px'
								/>
								<Skeleton
									h='134px'
									rounded='10px'
								/>
								<Skeleton
									h='134px'
									rounded='10px'
								/>
							</SimpleGrid>
						</Box>

						<Box
							bg='#F5F8FA'
							p={'21px'}
							rounded='10px'
						>
							<SlideFade in>
								<Box
									width={'100%'}
									display='flex'
									justifyContent={'space-between'}
									alignItems='center'
									mb={'7px'}
								>
									<Text
										fontSize={'22px'}
										fontWeight='600'
									>
										{t('modifyCompany.employees')}
									</Text>
									{company?.employees && company?.employees?.length > 0 && (
										<Button
											minW={'188px'}
											textAlign='center'
											fontSize='18px'
											fontWeight={700}
											rounded={'100px'}
											borderWidth={'1.5px'}
											borderColor={'#3FB2D2'}
											bg={'transparent'}
											color={'#3FB2D2'}
											onClick={() =>
												setCompany({
													...company,
													employees: [
														...(company.employees || []),
														{
															id: (Math.random() + 1).toString(36).substring(2),
															isActive: true,
														},
													],
												})
											}
										>
											<Box
												gap={'17px'}
												display={'flex'}
												alignItems={'center'}
											>
												<Text>{t('modifyCompany.addEmployee')}</Text>
												<CirclePlusIcon />
											</Box>
										</Button>
									)}
								</Box>

								<Table>
									<thead>
										<Tr>
											<Th>Name</Th>
											<Th>Phone</Th>
											<Th>ROLE</Th>
											<Th>Reporting</Th>
											<Th>Active</Th>
										</Tr>
									</thead>
									<tbody>
										<SkeletonRow width='75px' />
										<SkeletonRow width='125px' />
										<SkeletonRow width='50px' />
										<SkeletonRow width='100px' />
										<SkeletonRow width='20px' />
									</tbody>
								</Table>
							</SlideFade>
						</Box>

						<Box
							display={'flex'}
							flexDirection='row-reverse'
						>
							<Skeleton
								minW={isPc ? '200px' : 'full'}
								h='60px'
								rounded={'100px'}
							/>
						</Box>
					</Box>
				</>
			) : (
				company && (
					<>
						<Box
							padding={'22px'}
							width={'100%'}
							maxW={style['pc-view']}
							rounded='10px'
							bg={'#fff'}
							boxShadow='0px 0px 25px rgba(62, 62, 62, 0.08);'
							display={'flex'}
							flexDirection='column'
							gap={'20px'}
						>
							<Box
								bg='#F5F8FA'
								p={'21px'}
								rounded='10px'
							>
								<Text
									fontSize={'22px'}
									fontWeight='600'
									mb={'22px'}
								>
									{t('modifyCompany.companyDetails')}
								</Text>
								<VStack
									w='100%'
									spacing={2}
								>
									<SimpleGrid
										w='100%'
										columns={{ base: 1, md: 2 }}
										spacingX={style.gap}
										spacingY={style.gap}
									>
										<Box>
											<Text
												marginBottom={'5px'}
												fontSize={'14px'}
												color='#aaa'
												fontWeight='14px'
											>
												{t('modifyCompany.companyName')}
											</Text>
											<Input
												bg={'#fff'}
												border={'1px solid #E2E2E2'}
												rounded='10px'
												height={'54px'}
												maxLength={128}
												placeholder='Name *'
												isRequired
												value={company.name}
												onChange={(e) => setCompany({ ...company, name: e.target.value })}
											/>
										</Box>
										<Box>
											<Text
												marginBottom={'5px'}
												fontSize={'14px'}
												color='#aaa'
												fontWeight='14px'
											>
												{t('modifyCompany.field')}
											</Text>
											<Input
												maxLength={128}
												bg={'#fff'}
												height={'54px'}
												border={'1px solid #E2E2E2'}
												rounded='10px'
												placeholder='Field  (e.g.: Construction, Medical, Education)'
												value={company.field}
												onChange={(e) => setCompany({ ...company, field: e.target.value })}
											/>
										</Box>
										<Box>
											<Text
												marginBottom={'5px'}
												fontSize={'14px'}
												color='#aaa'
												fontWeight='14px'
											>
												{t('modifyCompany.siteLink')}
											</Text>
											<Input
												maxLength={128}
												bg={'#fff'}
												height={'54px'}
												border={'1px solid #E2E2E2'}
												rounded='10px'
												placeholder='Website'
												value={company.website}
												onChange={(e) => setCompany({ ...company, website: e.target.value })}
											/>
										</Box>

										<SimpleGrid columns={isMobile ? 2 : 4}>
											<Box>
												<Text
													marginBottom={'5px'}
													fontSize={'14px'}
													color='#aaa'
													fontWeight='14px'
												>
													{t('modifyCompany.active')}
												</Text>
												<Box
													sx={{
														display: 'flex',
														alignItems: 'center',
														paddingTop: '13px',
													}}
												>
													<div dir='ltr'>
														<Switch
															defaultChecked={company?.isActive}
															onChange={() => {
																const companyClone = { ...company }
																companyClone.isActive = !company?.isActive
																setCompany(companyClone)
															}}
														/>
													</div>
												</Box>
											</Box>

											<Box>
												<Text
													marginBottom={'5px'}
													fontSize={'14px'}
													color='#aaa'
													fontWeight='14px'
												>
													{t('modifyCompany.language')}
												</Text>
												<Box
													sx={{
														display: 'flex',
														alignItems: 'center',
													}}
												>
													<Select
														width='60px'
														//   value={i18n.language}
														value={company.language || 'en'}
														onChange={(e) =>
															setCompany({ ...company, language: e.target.value })
														}
														background={'white'}
														className={locale === 'en' ? 'en' : 'he'}
														minW={isPc ? '113px' : '70px'}
													>
														<option
															value='en'
															style={{ color: '#000' }}
															className='En'
														>
															EN
														</option>
														<option
															value='he'
															style={{ color: '#000' }}
															className='He'
														>
															HE
														</option>
													</Select>
												</Box>
											</Box>
											<Box>
												<Text
													marginBottom={'5px'}
													fontSize={'14px'}
													color='#aaa'
													fontWeight='14px'
												>
													{t('modifyCompany.tasksSms')}
												</Text>
												<Box
													sx={{
														display: 'flex',
														alignItems: 'center',
														paddingTop: '13px',
													}}
												>
													<div dir='ltr'>
														<Switch
															// defaultChecked={company?.tasksSms}
															isChecked={company?.tasksSms === true}
															onChange={() => {
																// const companyClone = { ...company }
																// companyClone.tasksSms = !company?.tasksSms
																// setCompany(companyClone)

																setCompany({
																	...company,
																	tasksSms: !company?.tasksSms,
																})
															}}
														/>
													</div>
												</Box>
											</Box>
											<Box>
												<Text
													marginBottom={'5px'}
													fontSize={'14px'}
													color='#aaa'
													fontWeight='14px'
												>
													{t('modifyCompany.tasksSmsTime')}
												</Text>
												<Box
													sx={{
														display: 'flex',
														alignItems: 'center',
													}}
												>
													<Input
														size='md'
														type='time'
														background={'white'}
														onChange={(e) => {
															setCompany({
																...company,
																tasksSmsTime: e.target.value,
															})
														}}
														value={company.tasksSmsTime || ''}
														//   disabled={!company?.tasksSms}
														disabled={false}
														readOnly={false}
													/>
												</Box>
											</Box>
										</SimpleGrid>
									</SimpleGrid>
								</VStack>
							</Box>

							<Box
								bg='#F5F8FA'
								p={'21px'}
								rounded='10px'
							>
								<Text
									fontSize={'22px'}
									fontWeight='600'
									mb={'22px'}
								>
									{t('modifyCompany.risks')}
								</Text>

								<SimpleGrid
									columns={isPc ? 3 : 1}
									spacing={2}
								>
									{company?.exposureCategories?.map((category: any, i: number) => (
										<Box
											pos='relative'
											key={'category-' + category.id + i}
										>
											<Text
												marginBottom={'5px'}
												fontSize={'14px'}
												color='#aaa'
												fontWeight='14px'
											>
												{t('modifyCompany.riskName')}
											</Text>
											<Box
												bg='#fff'
												border='1.2521px solid #E2E2E2'
												rounded='10px'
												display={'flex'}
												alignItems='center'
											>
												<Input
													border={'none'}
													value={category?.name}
													height={'54px'}
													isRequired
													onChange={(e) => {
														const companyClone = { ...company }
														companyClone.exposureCategories[i].name = e.target.value
														setCompany(companyClone)
													}}
													maxLength={128}
												/>
												<Button
													bg='transparent'
													color={'#3FB2D2'}
													onClick={async () => {
														const result = await Swal.fire({
															title: t('modifyCompany.deleteRisk') + ' ' + name + '?',
															text: t('modifyCompany.deleteWarning'),
															input: 'password',
															inputAttributes: {
																autocapitalize: 'off',
																placeholder: t('modifyCompany.enterPassword'),
															},
															showCancelButton: true,
															confirmButtonColor: '#3085d6',
															cancelButtonColor: '#d33',
															cancelButtonText: t('modifyCompany.cancel'),
															confirmButtonText: t('modifyCompany.delete'),
														})
														if (result.isConfirmed) {
															try {
																await CompanyService.checkAdminCode(result.value)
																const companyClone = { ...company }
																companyClone.exposureCategories =
																	companyClone.exposureCategories.filter(
																		(c: any) => c.id !== category.id
																	)
																setCompany(companyClone)
															} catch (err: any) {
																Swal.fire(
																	'',
																	t('modifyCompany.invalidPassword'),
																	'error'
																)
															}
														}
													}}
												>
													<TrashIcon />
												</Button>
											</Box>
										</Box>
									))}

									<Box
										border='1.2521px dashed #3FB2D2'
										rounded='10px'
										justifyContent='center'
										alignItems={'center'}
										display={'flex'}
										gap={'16px'}
										marginTop='20px'
										padding={'10px'}
										color={'#3FB2D2'}
										cursor='pointer'
										onClick={() =>
											setCompany({
												...company,
												exposureCategories: [
													...(company.exposureCategories || []),
													{
														id: (Math.random() + 1).toString(36).substring(2),
														name: 'Risk ' + ((company.exposureCategories || []).length + 1),
													},
												],
											})
										}
									>
										<Text
											fontWeight='700'
											fontSize={'18px'}
										>
											{t('modifyCompany.addRisk')}
										</Text>
										<CirclePlusIcon />
									</Box>
								</SimpleGrid>
							</Box>

							<Box
								bg='#F5F8FA'
								p={'21px'}
								rounded='10px'
							>
								<Text
									fontSize={'22px'}
									fontWeight='600'
									mb={'22px'}
								>
									{t('modifyCompany.roles')}
								</Text>
								<SimpleGrid
									w='100%'
									columns={isPc ? 3 : 1}
									spacing={2}
								>
									{company?.roles?.map((role, ix) => (
										<Box
											pos='relative'
											key={'role-' + role.id + ix}
											bg='#fff'
											rounded={'10px'}
											border='1.2521px solid #E2E2E2'
											padding={'14px'}
											display={'flex'}
											flexDirection='column'
											gap={'16px'}
										>
											<Box
												rounded={'10px'}
												bg='#F5F8FA'
												display='flex'
												alignItems={'center'}
											>
												<Input
													value={role?.name}
													height={'54px'}
													border='none'
													onChange={(e) => {
														const companyClone = { ...company }
														companyClone.roles[ix].name = e.target.value
														setCompany(companyClone)
													}}
													maxLength={128}
												/>
												<Button
													color={'#3FB2D2'}
													bg={'transparent'}
													onClick={() => {
														const companyClone = { ...company }
														companyClone.roles = companyClone.roles.filter(
															(r) => r.id !== role.id
														)
														setCompany(companyClone)
													}}
												>
													<TrashIcon />
												</Button>
											</Box>

											<Box
												display={'flex'}
												justifyContent='space-between'
												alignItems={'center'}
											>
												<Box
													display='flex'
													flexDirection={'column'}
												>
													<Checkbox
														isChecked={role.showExposure}
														size='lg'
														bg='white'
														fontSize='16px'
														colorScheme='white'
														borderColor='#3FB2D2'
														iconColor='#3FB2D2'
														iconSize={'17px'}
														fontWeight={400}
														onChange={(e) => {
															const companyClone = { ...company }
															companyClone.roles[ix].showExposure = e.target.checked
															setCompany(companyClone)
														}}
													>
														{t('modifyCompany.showRiskProgress')}
													</Checkbox>
													<Checkbox
														isChecked={role.showNewslatter}
														colorScheme='white'
														borderColor='#3FB2D2'
														iconColor='#3FB2D2'
														iconSize={'17px'}
														size='lg'
														onChange={(e) => {
															const companyClone = { ...company }
															companyClone.roles[ix].showNewslatter = e.target.checked
															setCompany(companyClone)
														}}
													>
														{t('modifyCompany.showNewsletters')}
													</Checkbox>
													<Checkbox
														isChecked={role.showQuestionnaires}
														colorScheme='white'
														borderColor='#3FB2D2'
														iconColor='#3FB2D2'
														iconSize={'17px'}
														size='lg'
														onChange={(e) => {
															const companyClone = { ...company }
															companyClone.roles[ix].showQuestionnaires = e.target.checked
															setCompany(companyClone)
														}}
													>
														{t('modifyCompany.showQuestionnaires')}
													</Checkbox>
												</Box>
												<ReportingDropdown
													company={company}
													setCompany={setCompany}
													role={role}
												/>
											</Box>
										</Box>
									))}

									<Box
										border='1.2521px dashed #3FB2D2'
										rounded='10px'
										minH='134px'
										color={'#3FB2D2'}
										cursor='pointer'
										display={'flex'}
										alignItems='center'
										justifyContent='center'
										gap={'15px'}
										onClick={() => {
											setCompany({
												...company,
												roles: [
													...(company.roles || []),
													{
														id: (Math.random() + 1).toString(36).substring(2),
														name: 'New Role ' + ((company.roles || []).length + 1),
														// showExposure: true,
														showNewslatter: true,
														showQuestionnaires: true,
													},
												],
											})
										}}
									>
										<Text
											fontSize={'18px'}
											fontWeight='700'
										>
											{t('modifyCompany.addRole')}
										</Text>
										<CirclePlusIcon />
									</Box>
								</SimpleGrid>
							</Box>

							<Employees
								company={company}
								setCompany={setCompany}
							/>

							<Box
								display={'flex'}
								flexDirection='row-reverse'
							>
								<Button
									color={'white'}
									minW={isPc ? '200px' : 'full'}
									height='60px'
									textAlign='center'
									border='3px solid #FFFFFF'
									background={'#3FB2D2'}
									fontSize='18px'
									fontWeight={700}
									rounded={'100px'}
									filter='drop-shadow(0px 4px 30px rgba(63, 178, 210, 0.44))'
									onClick={submit}
									isLoading={submitting}
								>
									{t('modifyCompany.approve')}
								</Button>
							</Box>
						</Box>
					</>
				)
			)}
		</Container>
	)
}

/**
 * const RolesDropdown = memo(
	({
		company,
		employee,
		setEmployee,
	}: {
		company: EditCompany
		employee: EditCompany['employees'][number]
		setEmployee: (employee: EditCompany['employees'][number]) => void
	}) => {
		const { t } = useTranslation()

		const items = company?.roles
			?.filter((r) => r.name)
			.map((r) => ({
				id: r.id!,
				label: r.name!,
				checked: employee?.roleIds?.includes(r.id!) || false,
				onChange: () =>
					setEmployee({
						...employee,
						roleIds: toggleIdFromArr(employee.roleIds || [], r.id!),
					}),
			}))

		const triggerLabel =
			company?.roles
				?.filter((r) => employee.roleIds?.includes(r.id!))
				.map((r) => r.name)
				.join(',') || t('modifyCompany.chooseRole')

		return (
			<Dropdown
				triggerLabel={triggerLabel}
				items={items}
			/>
		)
	}
)
 */

const ReportingDropdown = ({
	company,
	setCompany,
	role,
}: {
	company: EditCompany
	setCompany: (company: EditCompany) => void
	role: EditCompany['roles'][number]
}) => {
	const { t } = useTranslation()

	const items: DropdownProps['items'] = company?.roles
		?.filter((x) => x.id !== role.id)
		?.filter((x) => x.name)
		?.map((r, i) => ({
			id: r.id!,
			label: r.name!,
			checked: (role?.parentIds || []).includes(r.id!),
			onChange: () => {
				const companyClone = { ...company }
				companyClone.roles = companyClone.roles.map((rr) => {
					if (rr.id === role.id) {
						rr.parentIds = rr.parentIds || []
						if (!rr.parentIds.includes(r.id!)) {
							rr.parentIds.push(r.id!)
						} else {
							rr.parentIds = rr.parentIds.filter((p) => p !== r.id!)
						}
					}
					return rr
				})

				setCompany(companyClone)
			},
		}))

	return (
		<Dropdown
			triggerLabel={t('modifyCompany.reporting')}
			items={items}
			trigger={
				<Box
					bg='#3FB2D2'
					color='#fff'
					rounded={'100px'}
					lineHeight='42px'
					minWidth={'112px'}
					display={'flex'}
					alignItems={'center'}
					justifyContent={'center'}
					paddingX={4}
					cursor='pointer'
				>
					{t('modifyCompany.reporting')}
				</Box>
			}
		/>
	)
}

export default ModifyCompany
